import { useState } from "react";
import { CloseIcon, Dropdown, MenuIcon } from "@moznsystems/mozn-ui";
import logoMini from "@/assets/images/logo_mini.svg";
import { FOCAL_ROUTE_STAGING, FOCAL_ROUTE_PROD } from "@/constants/routes";

const AppSwitcher = () => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    return (
        <Dropdown
            isOpen={isDropdownVisible}
            onOpen={() => setDropdownVisible(true)}
            onClose={() => setDropdownVisible(false)}
            target={
                <button className="open-dropdown-button">
                    <MenuIcon className="side-bar__logo_container--drop-icon" />
                </button>
            }
            usePortal
            zIndex={10000}
        >
            <div className="side-bar__logo_container__dropdown">
                <p className="side-bar__logo_container__dropdown__head">
                    <span>Switch To</span>
                    <CloseIcon onClick={handleDropdown} fill="#C0C0CB" />
                </p>
                <a className="side-bar__logo_container__dropdown__link side-bar__logo_container__dropdown__link--active">
                    <img src={logoMini} alt="Focal's logo" />
                    <p>Focal Fraud</p>
                </a>
                <p className="separator" />
                <a
                    href={
                        window.location.hostname.includes("staging")
                            ? FOCAL_ROUTE_STAGING
                            : FOCAL_ROUTE_PROD
                    }
                    className="side-bar__logo_container__dropdown__link"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={logoMini} alt="Focal's logo" />
                    <p>Focal AML</p>
                </a>
            </div>
        </Dropdown>
    );
};

export default AppSwitcher;
