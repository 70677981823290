import {
    DASHBOARD_ROUTE,
    EVENTS_ROUTE,
    CUSTOMERS_ROUTE,
    DEVICES_ROUTE,
    SETTINGS_ROUTE,
    LISTS_ROUTE,
} from "./routes";
import {
    CUSTOMERS,
    DASHBOARD,
    DEVICES,
    EVENTS,
    LISTS,
    SETTINGS,
} from "./constants";
import {
    CustomersIcon,
    DashboardIcon,
    DevicesSolidIcon,
    ListIcon,
    SettingsSolidIcon,
    ShieldIcon,
} from "@moznsystems/mozn-ui";

export const navigation = [
    {
        label: DASHBOARD,
        url: DASHBOARD_ROUTE,
        icon: DashboardIcon,
    },
    {
        label: EVENTS,
        url: EVENTS_ROUTE,
        icon: ShieldIcon,
    },
    {
        label: CUSTOMERS,
        url: CUSTOMERS_ROUTE,
        icon: CustomersIcon,
    },
    {
        label: DEVICES,
        url: DEVICES_ROUTE,
        icon: DevicesSolidIcon,
    },
    {
        label: LISTS,
        url: LISTS_ROUTE,
        icon: ListIcon,
    },
    {
        label: SETTINGS,
        url: SETTINGS_ROUTE,
        icon: SettingsSolidIcon,
    },
];
